// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllMentorsAction, GetAllMentorsGlobalAction,
         SelectMentorGlobalAction, SelectMentorAction,
         SelectMentorCoursesAction, CreateMentorInterface,
         CreateMentorAction, UpdateMentorInterface,
         UpdateMentorAction, DeleteMentorAction } from '../Actions/MentorAction';

// Interface
interface IMentors {
    loading?: boolean;
    mentors?: any;
    mentor?: any;
    courses?: any;
    error?: any;
}

// Initial State
const initialState: IMentors = {
    loading: false,
    mentors: [],
    mentor: {},
    courses: [],
    error: ''
}

// Get All Mentors
export const GetAllMentors = createAsyncThunk(
    'Mentors',
    async () => {
        return await GetAllMentorsAction();
    }
)

// Get All Mentors Globally
export const GetAllMentorsGlobal = createAsyncThunk(
    'Mentors/Global',
    async () => {
        return await GetAllMentorsGlobalAction();
    }
)

// Select Mentor
export const SelectMentor = createAsyncThunk(
    'Mentors/Select',
    async (Payload: number) => {
        return await SelectMentorAction(Payload);
    }
)

// Select Mentor Globally
export const SelectMentorGlobal = createAsyncThunk(
    'Mentors/Select/Global',
    async (Payload: number) => {
        return await SelectMentorGlobalAction(Payload);
    }
)

// Select Mentor Courses
export const SelectMentorCourses = createAsyncThunk(
    'Mentors/Select/Courses',
    async (Payload: number) => {
        return await SelectMentorCoursesAction(Payload);
    }
)

// Create Mentor
export const CreateMentor = createAsyncThunk(
    'Mentors/Create',
    async (Payload: CreateMentorInterface) => {
        return await CreateMentorAction(Payload);
    }
)

// Update Mentor
export const UpdateMentor = createAsyncThunk(
    'Mentors/Update',
    async (Payload: UpdateMentorInterface) => {
        return await UpdateMentorAction(Payload);
    }
)

// Delete Mentor
export const DeleteMentor = createAsyncThunk(
    'Mentors/Delete',
    async (Payload: number) => {
        return await DeleteMentorAction(Payload);
    }
)

const MentorSlice = createSlice({
    name: 'Mentors',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Mentors
        Builder.addCase( GetAllMentors.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllMentors.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentors = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllMentors.rejected ,( State, Action) => {
            State.loading = false;
            State.mentors = [];
            State.error = Action.error?.message;
        })

        // Get All Mentors Globally
        Builder.addCase( GetAllMentorsGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllMentorsGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentors = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllMentorsGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.mentors = [];
            State.error = Action.error?.message;
        })

        // Select Mentor
        Builder.addCase( SelectMentor.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectMentor.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentor = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectMentor.rejected ,( State, Action) => {
            State.loading = false;
            State.mentor = {};
            State.error = Action.error?.message;
        })

        // Select Mentor Globally
        Builder.addCase( SelectMentorGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectMentorGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentor = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectMentorGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.mentor = {};
            State.error = Action.error?.message;
        })

        // Select Mentor Courses
        Builder.addCase( SelectMentorCourses.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectMentorCourses.fulfilled, ( State, Action) => {
            State.loading = false;
            State.courses = Action.payload?.data?.courses;
            State.error = '';
        })
        Builder.addCase( SelectMentorCourses.rejected ,( State, Action) => {
            State.loading = false;
            State.courses = [];
            State.error = Action.error?.message;
        })

        // Create Mentor
        Builder.addCase( CreateMentor.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateMentor.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentor = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateMentor.rejected ,( State, Action) => {
            State.loading = false;
            State.mentor = {};
            State.error = Action.error?.message;
        })

        // Update Mentor
        Builder.addCase( UpdateMentor.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateMentor.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentor = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateMentor.rejected ,( State, Action) => {
            State.loading = false;
            State.mentor = {};
            State.error = Action.error?.message;
        })

        // Delete Mentor
        Builder.addCase( DeleteMentor.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteMentor.fulfilled, ( State, Action) => {
            State.loading = false;
            State.mentor = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteMentor.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectMentors = ( State: RootState ) => State.Mentor;
export default MentorSlice.reducer;