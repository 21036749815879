// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Client Sign In Interface
export interface ClientSignInInterface {
    Data: {
        email: string;
        password: string;
    };
    Navigate: any;
}

// Client Sign In
export const ClientSignInAction = async ( Payload: ClientSignInInterface ) => {
    const response = await Request.post( '/clients/sign-in', Payload.Data );
    Payload.Navigate('/', { replace: true });
    return response.data;
}

// Client Sign Up Interface
export interface ClientSignUpInterface {
    Data: {
        name: string;
        email: string;
        mobile: string;
        password: string;
        password_confirmation: string;
    };
    Navigate: any;
}

// Client Sign Up
export const ClientSignUpAction = async ( Payload: ClientSignUpInterface ) => {
    const response = await Request.post( '/clients/sign-up', Payload.Data );
    Payload.Navigate('/', { replace: true });
    return response.data;
}

// Client Email Verified Interface
export interface ClientEmailVerifiedInterface {
    Data: {
        id: string;
        hash: string;
    };
    Navigate: any;
}

// Client Email Verified
export const ClientEmailVerifiedAction = async ( Payload: ClientEmailVerifiedInterface ) => {
    const response = await Request.post( '/clients/email-verified', Payload.Data );
    Payload.Navigate('/', { replace: true });
    return response.data;
}

// Client Profile
export const ClientProfileAction = async () => {
    const response = await Request.get( '/clients/profile' );
    return response.data;
}

// Client Sign Out
export const ClientSignOutAction = async ( Payload: any ) => {
    const response = await Request.post( '/clients/sign-out' );
    localStorage.clear();
    Payload.Navigate('/', { replace: true });
    return response.data;
}