// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Countries
export const GetAllCountriesAction = async () => {
    const response = await Request.get( '/countries' );
    return response.data;
}

// Select Country
export const SelectCountryAction = async ( Payload: number ) => {
    const response = await Request.get( `/categories/${Payload}` );
    return response.data;
}

// Select Country States
export const SelectCountryStatesAction = async ( Payload: number ) => {
    const response = await Request.get( `/categories/${Payload}/states` );
    return response.data;
}