// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { UserSignInInterface, UserSignInAction,
         UserSignUpInterface, UserSignUpAction,
         UserEmailVerifiedInterface, UserEmailVerifiedAction,
         UserSignOutAction, UserProfileAction } from '../Actions/UserAction';

// Interface
interface IUsers {
    loading?: boolean;
    user?: any;
    error?: any;
}

// Initial State
const initialState: IUsers = {
    loading: false,
    user: {},
    error: ''
}

// User Sign In
export const UserSignIn = createAsyncThunk(
    'Users/SignIn',
    async (Payload: UserSignInInterface) => {
        return await UserSignInAction(Payload);
    }
)

// User Sign Up
export const UserSignUp = createAsyncThunk(
    'Users/SignUp',
    async (Payload: UserSignUpInterface) => {
        return await UserSignUpAction(Payload);
    }
)

// Client Email Verified
export const UserEmailVerified = createAsyncThunk(
    'Users/EmailVerified',
    async (Payload: UserEmailVerifiedInterface) => {
        return await UserEmailVerifiedAction(Payload);
    }
)

// User Profile
export const UserProfile = createAsyncThunk(
    'Users/Profile',
    async () => {
        return await UserProfileAction();
    }
)

// User Sign Out
export const UserSignOut = createAsyncThunk(
    'Users/SignOut',
    async (Payload: any) => {
        return await UserSignOutAction(Payload);
    }
)

const UserSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // User Sign In
        Builder.addCase( UserSignIn.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UserSignIn.fulfilled, ( State, Action ) => {
            State.loading = false;
            State.user = Action.payload?.data?.user;
            State.error = '';
            localStorage.setItem( 'token', JSON.stringify(Action.payload?.data?.token) );
            toast.success(`Welcome ${Action.payload?.data?.user?.name}!`, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UserSignIn.rejected ,( State, Action) => {
            State.loading = false;
            State.user = [];
            State.error = Action.error?.message;
        })

        // User Sign Up
        Builder.addCase( UserSignUp.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UserSignUp.fulfilled, ( State, Action ) => {
            State.loading = false;
            State.user = Action.payload?.data?.user;
            State.error = '';
            localStorage.setItem( 'token', JSON.stringify(Action.payload?.data?.token) );
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UserSignUp.rejected ,( State, Action) => {
            State.loading = false;
            State.user = [];
            State.error = Action.error?.message;
        })

        // User Email Verified
        Builder.addCase( UserEmailVerified.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UserEmailVerified.fulfilled, ( State, Action ) => {
            State.loading = false;
            State.user = {...State.user};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UserEmailVerified.rejected ,( State, Action) => {
            State.loading = false;
            State.user = [];
            State.error = Action.error?.message;
        })

        // User Profile
        Builder.addCase( UserProfile.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UserProfile.fulfilled, ( State, Action) => {
            State.loading = false;
            State.user = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( UserProfile.rejected ,( State, Action) => {
            State.loading = false;
            State.user = [];
            State.error = Action.error?.message;
        })

        // User Sign Out
        Builder.addCase( UserSignOut.fulfilled, ( State, Action) => {
            State.loading = false;
            State.user = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UserSignOut.rejected ,( State, Action) => {
            State.loading = false;
            State.user = [];
            State.error = Action.error?.message;
        })

    },
});

export const SelectUsers = ( State: RootState ) => State.User;
export default UserSlice.reducer;