// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Categories
export const GetAllCategoriesAction = async () => {
    const response = await Request.get( '/categories' );
    return response.data;
}

// Get All Categories Globally
export const GetAllCategoriesGlobalAction = async () => {
    const response = await Request.get( '/categories/global' );
    return response.data;
}

// Select Category
export const SelectCategoryAction = async ( Payload: number ) => {
    const response = await Request.get( `/categories/${Payload}` );
    return response.data;
}

// Select Category Globally
export const SelectCategoryGlobalAction = async ( Payload: number ) => {
    const response = await Request.get( `/categories/${Payload}/global` );
    return response.data;
}

// Select Category Courses
export const SelectCategoryCoursesAction = async ( Payload: number ) => {
    const response = await Request.get( `/categories/${Payload}/courses` );
    return response.data;
}

// Create Category Interface
export interface CreateCategoryInterface {
    name: string;
    description: string;
    parent_id?: string[];
    image: any;
}

// Create Category
export const CreateCategoryAction = async ( Payload: CreateCategoryInterface ) => {
    const response = await Request.post( '/categories', Payload );
    return response.data;
}

// Update Category Interface
export interface UpdateCategoryInterface {
    id: number;
    name: string;
    description: string;
    parent_id?: string[];
    image: any;
}

// Update Category
export const UpdateCategoryAction = async ( Payload: UpdateCategoryInterface ) => {
    const response = await Request.post( `/categories/${Payload.id}`, Payload );
    return response.data;
}

// Delete Category
export const DeleteCategoryAction = async ( Payload: number ) => {
    const response = await Request.delete( `/categories/${Payload}` );
    return response.data;
}