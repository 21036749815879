// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Downloads
export const GetAllDownloadsAction = async () => {
    const response = await Request.get( '/downloads' );
    return response.data;
}

// Get All Downloads Globally
export const GetAllDownloadsGlobalAction = async () => {
    const response = await Request.get( '/downloads/global' );
    return response.data;
}

// Select Download
export const SelectDownloadAction = async ( Payload: number ) => {
    const response = await Request.get( `/downloads/${Payload}` );
    return response.data;
}

// Select Download Globally
export const SelectDownloadGlobalAction = async ( Payload: number ) => {
    const response = await Request.get( `/downloads/${Payload}/global` );
    return response.data;
}

// Create Download Interface
export interface CreateDownloadInterface {
    name: string;
    file: any;
}

// Create Download
export const CreateDownloadAction = async ( Payload: CreateDownloadInterface ) => {
    const response = await Request.post( '/downloads', Payload );
    return response.data;
}

// Update Download Interface
export interface UpdateDownloadInterface {
    id: number;
    name: string;
    file: any;
}

// Update Download
export const UpdateDownloadAction = async ( Payload: UpdateDownloadInterface ) => {
    const response = await Request.post( `/downloads/${Payload.id}`, Payload );
    return response.data;
}

// Delete Download
export const DeleteDownloadAction = async ( Payload: number ) => {
    const response = await Request.delete( `/downloads/${Payload}` );
    return response.data;
}