// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllAccreditationsAction, GetAllAccreditationsGlobalAction,
         SelectAccreditationGlobalAction, SelectAccreditationCoursesAction,
         SelectAccreditationAction, CreateAccreditationInterface,
         CreateAccreditationAction, UpdateAccreditationInterface,
         UpdateAccreditationAction, DeleteAccreditationAction } from '../Actions/AccreditationAction';

// Interface
interface ICategories {
    loading?: boolean;
    accreditations?: any;
    accreditation?: any;
    courses?: any;
    error?: any;
}

// Initial State
const initialState: ICategories = {
    loading: false,
    accreditations: [],
    accreditation: {},
    courses: [],
    error: ''
}

// Get All Accreditations
export const GetAllAccreditations = createAsyncThunk(
    'Accreditations',
    async () => {
        return await GetAllAccreditationsAction();
    }
)

// Get All Accreditations Globally
export const GetAllAccreditationsGlobal = createAsyncThunk(
    'Accreditations/Global',
    async () => {
        return await GetAllAccreditationsGlobalAction();
    }
)

// Select Accreditation
export const SelectAccreditation = createAsyncThunk(
    'Accreditations/Select',
    async (Payload: number) => {
        return await SelectAccreditationAction(Payload);
    }
)

// Select Accreditation Globally
export const SelectAccreditationGlobal = createAsyncThunk(
    'Accreditations/Select/Global',
    async (Payload: number) => {
        return await SelectAccreditationGlobalAction(Payload);
    }
)

// Select Accreditation Courses
export const SelectAccreditationCourses = createAsyncThunk(
    'Accreditations/Select/Courses',
    async (Payload: number) => {
        return await SelectAccreditationCoursesAction(Payload);
    }
)

// Create Accreditation
export const CreateAccreditation = createAsyncThunk(
    'Accreditations/Create',
    async (Payload: CreateAccreditationInterface) => {
        return await CreateAccreditationAction(Payload);
    }
)

// Update Accreditation
export const UpdateAccreditation = createAsyncThunk(
    'Accreditations/Update',
    async (Payload: UpdateAccreditationInterface) => {
        return await UpdateAccreditationAction(Payload);
    }
)

// Delete Accreditation
export const DeleteAccreditation = createAsyncThunk(
    'Accreditations/Delete',
    async (Payload: number) => {
        return await DeleteAccreditationAction(Payload);
    }
)

const AccreditationSlice = createSlice({
    name: 'Accreditations',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Accreditations
        Builder.addCase( GetAllAccreditations.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllAccreditations.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditations = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllAccreditations.rejected ,( State, Action) => {
            State.loading = false;
            State.accreditations = [];
            State.error = Action.error?.message;
        })

        // Get All Accreditations Globally
        Builder.addCase( GetAllAccreditationsGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllAccreditationsGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditations = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllAccreditationsGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.accreditations = [];
            State.error = Action.error?.message;
        })

        // Select Accreditation
        Builder.addCase( SelectAccreditation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectAccreditation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditation = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectAccreditation.rejected ,( State, Action) => {
            State.loading = false;
            State.accreditation = {};
            State.error = Action.error?.message;
        })

        // Select Accreditation Globally
        Builder.addCase( SelectAccreditationGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectAccreditationGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditation = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectAccreditationGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.accreditation = {};
            State.error = Action.error?.message;
        })

        // Select Accreditation Courses
        Builder.addCase( SelectAccreditationCourses.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectAccreditationCourses.fulfilled, ( State, Action) => {
            State.loading = false;
            State.courses = Action.payload?.data?.courses;
            State.error = '';
        })
        Builder.addCase( SelectAccreditationCourses.rejected ,( State, Action) => {
            State.loading = false;
            State.courses = [];
            State.error = Action.error?.message;
        })

        // Create Accreditation
        Builder.addCase( CreateAccreditation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateAccreditation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditation = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateAccreditation.rejected ,( State, Action) => {
            State.loading = false;
            State.accreditation = {};
            State.error = Action.error?.message;
        })

        // Update Accreditation
        Builder.addCase( UpdateAccreditation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateAccreditation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditation = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateAccreditation.rejected ,( State, Action) => {
            State.loading = false;
            State.accreditation = {};
            State.error = Action.error?.message;
        })

        // Delete Accreditation
        Builder.addCase( DeleteAccreditation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteAccreditation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.accreditation = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteAccreditation.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectAccreditations = ( State: RootState ) => State.Accreditation;
export default AccreditationSlice.reducer;