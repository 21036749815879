// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// User Sign In Interface
export interface UserSignInInterface {
    Data: {
        email: string;
        password: string;
    };
    Navigate: any;
}

// User Sign In
export const UserSignInAction = async ( Payload: UserSignInInterface ) => {
    const response = await Request.post( '/users/sign-in', Payload.Data );
    Payload.Navigate('/dashboard', { replace: true });
    return response.data;
}

// User Sign Up Interface
export interface UserSignUpInterface {
    Data: {
        name: string;
        email: string;
        mobile: string;
        password: string;
        password_confirmation: string;
    };
    Navigate: any;
}

// User Sign Up
export const UserSignUpAction = async ( Payload: UserSignUpInterface ) => {
    const response = await Request.post( '/users/sign-up', Payload.Data );
    Payload.Navigate('/dashboard', { replace: true });
    return response.data;
}

// User Email Verified Interface
export interface UserEmailVerifiedInterface {
    Data: {
        id: string;
        hash: string;
    };
    Navigate: any;
}

// User Email Verified
export const UserEmailVerifiedAction = async ( Payload: UserEmailVerifiedInterface ) => {
    const response = await Request.post( '/users/email-verified', Payload.Data );
    Payload.Navigate('/', { replace: true });
    return response.data;
}

// User Profile
export const UserProfileAction = async () => {
    const response = await Request.get( '/users/profile' );
    return response.data;
}

// User Sign Out
export const UserSignOutAction = async ( Payload: any ) => {
    const response = await Request.post( '/users/sign-out' );
    localStorage.clear();
    Payload.Navigate('/dashboard/sign-in', { replace: true });
    return response.data;
}