// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllCountriesAction, SelectCountryAction,
         SelectCountryStatesAction } from '../Actions/CountryAction';

// Interface
interface ICountries {
    loading?: boolean;
    countries?: any;
    country?: any;
    states?: any;
    error?: any;
}

// Initial State
const initialState: ICountries = {
    loading: false,
    countries: [],
    country: {},
    states: [],
    error: ''
}

// Get All Countries
export const GetAllCountries = createAsyncThunk(
    'Countries',
    async () => {
        return await GetAllCountriesAction();
    }
)

// Select Country
export const SelectCountry = createAsyncThunk(
    'Countries/Select',
    async (Payload: number) => {
        return await SelectCountryAction(Payload);
    }
)

// Select Country States
export const SelectCountryStates = createAsyncThunk(
    'Countries/Select/States',
    async (Payload: number) => {
        return await SelectCountryStatesAction(Payload);
    }
)

const CountrySlice = createSlice({
    name: 'Countries',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Countries
        Builder.addCase( GetAllCountries.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllCountries.fulfilled, ( State, Action) => {
            State.loading = false;
            State.countries = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( GetAllCountries.rejected ,( State, Action) => {
            State.loading = false;
            State.countries = [];
            State.error = Action.error?.message;
        })

        // Select Country
        Builder.addCase( SelectCountry.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCountry.fulfilled, ( State, Action) => {
            State.loading = false;
            State.country = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectCountry.rejected ,( State, Action) => {
            State.loading = false;
            State.country = {};
            State.error = Action.error?.message;
        })

        // Select Country States
        Builder.addCase( SelectCountryStates.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCountryStates.fulfilled, ( State, Action) => {
            State.loading = false;
            State.states = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectCountryStates.rejected ,( State, Action) => {
            State.loading = false;
            State.states = {};
            State.error = Action.error?.message;
        })

    },
});

export const SelectCountries = ( State: RootState ) => State.Country;
export default CountrySlice.reducer;