// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Mentors
export const GetAllMentorsAction = async () => {
    const response = await Request.get( '/mentors' );
    return response.data;
}

// Get All Mentors Globally
export const GetAllMentorsGlobalAction = async () => {
    const response = await Request.get( '/mentors/global' );
    return response.data;
}

// Select Mentor
export const SelectMentorAction = async ( Payload: number ) => {
    const response = await Request.get( `/mentors/${Payload}` );
    return response.data;
}

// Select Mentor Globally
export const SelectMentorGlobalAction = async ( Payload: number ) => {
    const response = await Request.get( `/mentors/${Payload}/global` );
    return response.data;
}

// Select Mentor Courses
export const SelectMentorCoursesAction = async ( Payload: number ) => {
    const response = await Request.get( `/mentors/${Payload}/courses` );
    return response.data;
}

// Create Mentor Interface
export interface CreateMentorInterface {
    name: string;
    title: string;
    about: string;
    email: string;
    mobile: string;
    image: any;
}

// Create Mentor
export const CreateMentorAction = async ( Payload: CreateMentorInterface ) => {
    const response = await Request.post( '/mentors', Payload );
    return response.data;
}

// Update Mentor Interface
export interface UpdateMentorInterface {
    id: number;
    name: string;
    title: string;
    about: string;
    email: string;
    mobile: string;
    image: any;
}

// Update Mentor
export const UpdateMentorAction = async ( Payload: UpdateMentorInterface ) => {
    const response = await Request.post( `/mentors/${Payload.id}`, Payload );
    return response.data;
}

// Delete Mentor
export const DeleteMentorAction = async ( Payload: number ) => {
    const response = await Request.delete( `/mentors/${Payload}` );
    return response.data;
}