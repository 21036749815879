// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllCoursesAction, GetAllCoursesGlobalAction,
         SelectCourseGlobalAction, SelectCourseAction,
         CreateCourseInterface, CreateCourseAction,
         UpdateCourseInterface, UpdateCourseAction,
         DeleteCourseAction } from '../Actions/CourseAction';

// Interface
interface ICourses {
    loading?: boolean;
    courses?: any;
    course?: any;
    error?: any;
}

// Initial State
const initialState: ICourses = {
    loading: false,
    courses: [],
    course: {},
    error: ''
}

// Get All Courses
export const GetAllCourses = createAsyncThunk(
    'Courses',
    async () => {
        return await GetAllCoursesAction();
    }
)

// Get All Courses Globally
export const GetAllCoursesGlobal = createAsyncThunk(
    'Courses/Global',
    async () => {
        return await GetAllCoursesGlobalAction();
    }
)

// Select Course
export const SelectCourse = createAsyncThunk(
    'Courses/Select',
    async (Payload: number) => {
        return await SelectCourseAction(Payload);
    }
)

// Select Course Globally
export const SelectCourseGlobal = createAsyncThunk(
    'Courses/Select/Global',
    async (Payload: number) => {
        return await SelectCourseGlobalAction(Payload);
    }
)

// Create Course
export const CreateCourse = createAsyncThunk(
    'Courses/Create',
    async (Payload: CreateCourseInterface) => {
        return await CreateCourseAction(Payload);
    }
)

// Update Course
export const UpdateCourse = createAsyncThunk(
    'Courses/Update',
    async (Payload: UpdateCourseInterface) => {
        return await UpdateCourseAction(Payload);
    }
)

// Delete Course
export const DeleteCourse = createAsyncThunk(
    'Courses/Delete',
    async (Payload: number) => {
        return await DeleteCourseAction(Payload);
    }
)

const CourseSlice = createSlice({
    name: 'Courses',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Courses
        Builder.addCase( GetAllCourses.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllCourses.fulfilled, ( State, Action) => {
            State.loading = false;
            State.courses = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( GetAllCourses.rejected ,( State, Action) => {
            State.loading = false;
            State.courses = [];
            State.error = Action.error?.message;
        })

        // Get All Courses Globally
        Builder.addCase( GetAllCoursesGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllCoursesGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.courses = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( GetAllCoursesGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.courses = [];
            State.error = Action.error?.message;
        })

        // Select Course
        Builder.addCase( SelectCourse.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCourse.fulfilled, ( State, Action) => {
            State.loading = false;
            State.course = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectCourse.rejected ,( State, Action) => {
            State.loading = false;
            State.course = {};
            State.error = Action.error?.message;
        })

        // Select Course Globally
        Builder.addCase( SelectCourseGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCourseGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.course = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectCourseGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.course = {};
            State.error = Action.error?.message;
        })

        // Create Course
        Builder.addCase( CreateCourse.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateCourse.fulfilled, ( State, Action) => {
            State.loading = false;
            State.course = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateCourse.rejected ,( State, Action) => {
            State.loading = false;
            State.course = {};
            State.error = Action.error?.message;
        })

        // Update Course
        Builder.addCase( UpdateCourse.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateCourse.fulfilled, ( State, Action) => {
            State.loading = false;
            State.course = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateCourse.rejected ,( State, Action) => {
            State.loading = false;
            State.course = {};
            State.error = Action.error?.message;
        })

        // Delete Course
        Builder.addCase( DeleteCourse.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteCourse.fulfilled, ( State, Action) => {
            State.loading = false;
            State.course = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteCourse.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectCourses = ( State: RootState ) => State.Course;
export default CourseSlice.reducer;