// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { ClientSignInInterface, ClientSignInAction,
         ClientSignUpInterface, ClientSignUpAction,
         ClientEmailVerifiedInterface, ClientEmailVerifiedAction,
         ClientSignOutAction, ClientProfileAction } from '../Actions/ClientAction';

// Interface
interface IClients {
    loading?: boolean;
    client?: any;
    error?: any;
}

// Initial State
const initialState: IClients = {
    loading: false,
    client: {},
    error: ''
}

// Client Sign In
export const ClientSignIn = createAsyncThunk(
    'Clients/SignIn',
    async (Payload: ClientSignInInterface) => {
        return await ClientSignInAction(Payload);
    }
)

// Client Sign Up
export const ClientSignUp = createAsyncThunk(
    'Clients/SignUp',
    async (Payload: ClientSignUpInterface) => {
        return await ClientSignUpAction(Payload);
    }
)

// Client Email Verified
export const ClientEmailVerified = createAsyncThunk(
    'Clients/EmailVerified',
    async (Payload: ClientEmailVerifiedInterface) => {
        return await ClientEmailVerifiedAction(Payload);
    }
)

// Client Profile
export const ClientProfile = createAsyncThunk(
    'Clients/Profile',
    async () => {
        return await ClientProfileAction();
    }
)

// Client Sign Out
export const ClientSignOut = createAsyncThunk(
    'Clients/SignOut',
    async (Payload: any) => {
        return await ClientSignOutAction(Payload);
    }
)

const ClientSlice = createSlice({
    name: 'Clients',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Client Sign In
        Builder.addCase( ClientSignIn.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( ClientSignIn.fulfilled, ( State, Action ) => {
            State.loading = false;
            State.client = Action.payload?.data?.client;
            State.error = '';
            localStorage.setItem( 'token', JSON.stringify(Action.payload?.data?.token) );
            toast.success(`Welcome ${Action.payload?.data?.client?.name}!`, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( ClientSignIn.rejected ,( State, Action) => {
            State.loading = false;
            State.client = [];
            State.error = Action.error?.message;
        })

        // Client Sign Up
        Builder.addCase( ClientSignUp.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( ClientSignUp.fulfilled, ( State, Action ) => {
            State.loading = false;
            State.client = Action.payload?.data?.client;
            State.error = '';
            localStorage.setItem( 'token', JSON.stringify(Action.payload?.data?.token) );
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( ClientSignUp.rejected ,( State, Action) => {
            State.loading = false;
            State.client = [];
            State.error = Action.error?.message;
        })

        // Client Email Verified
        Builder.addCase( ClientEmailVerified.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( ClientEmailVerified.fulfilled, ( State, Action ) => {
            State.loading = false;
            State.client = {...State.client};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( ClientEmailVerified.rejected ,( State, Action) => {
            State.loading = false;
            State.client = [];
            State.error = Action.error?.message;
        })

        // Client Profile
        Builder.addCase( ClientProfile.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( ClientProfile.fulfilled, ( State, Action) => {
            State.loading = false;
            State.client = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( ClientProfile.rejected ,( State, Action) => {
            State.loading = false;
            State.client = [];
            State.error = Action.error?.message;
        })

        // Client Sign Out
        Builder.addCase( ClientSignOut.fulfilled, ( State, Action) => {
            State.loading = false;
            State.client = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( ClientSignOut.rejected ,( State, Action) => {
            State.loading = false;
            State.client = [];
            State.error = Action.error?.message;
        })

    },
});

export const SelectClients = ( State: RootState ) => State.Client;
export default ClientSlice.reducer;