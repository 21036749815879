// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllFaqsCoursesAction, GetAllFaqsMentorsAction,
         GetAllFaqsAction, SelectFaqAction,
         CreateFaqInterface, CreateFaqAction,
         UpdateFaqInterface, UpdateFaqAction,
         DeleteFaqAction } from '../Actions/FaqAction';

// Interface
interface IFaqs {
    loading?: boolean;
    faqs?: any;
    faq?: any;
    error?: any;
}

// Initial State
const initialState: IFaqs = {
    loading: false,
    faqs: [],
    faq: {},
    error: ''
}

// Get All Faqs
export const GetAllFaqs = createAsyncThunk(
    'Faqs',
    async () => {
        return await GetAllFaqsAction();
    }
)

// Get All Faqs For Courses
export const GetAllFaqsCourses = createAsyncThunk(
    'Faqs/Courses',
    async () => {
        return await GetAllFaqsCoursesAction();
    }
)

// Get All Faqs For Mentors
export const GetAllFaqsMentors = createAsyncThunk(
    'Faqs/Mentors',
    async () => {
        return await GetAllFaqsMentorsAction();
    }
)

// Select Faq
export const SelectFaq = createAsyncThunk(
    'Faqs/Select',
    async (Payload: number) => {
        return await SelectFaqAction(Payload);
    }
)

// Create Faq
export const CreateFaq = createAsyncThunk(
    'Faqs/Create',
    async (Payload: CreateFaqInterface) => {
        return await CreateFaqAction(Payload);
    }
)

// Update Faq
export const UpdateFaq = createAsyncThunk(
    'Faqs/Update',
    async (Payload: UpdateFaqInterface) => {
        return await UpdateFaqAction(Payload);
    }
)

// Delete Faq
export const DeleteFaq = createAsyncThunk(
    'Faqs/Delete',
    async (Payload: number) => {
        return await DeleteFaqAction(Payload);
    }
)

const FaqSlice = createSlice({
    name: 'Faqs',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Faqs
        Builder.addCase( GetAllFaqs.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllFaqs.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faqs = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllFaqs.rejected ,( State, Action) => {
            State.loading = false;
            State.faqs = [];
            State.error = Action.error?.message;
        })

        // Get All Faqs For Courses
        Builder.addCase( GetAllFaqsCourses.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllFaqsCourses.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faqs = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllFaqsCourses.rejected ,( State, Action) => {
            State.loading = false;
            State.faqs = [];
            State.error = Action.error?.message;
        })

        // Get All Faqs For Mentors
        Builder.addCase( GetAllFaqsMentors.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllFaqsMentors.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faqs = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllFaqsMentors.rejected ,( State, Action) => {
            State.loading = false;
            State.faqs = [];
            State.error = Action.error?.message;
        })

        // Select Faq
        Builder.addCase( SelectFaq.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectFaq.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faq = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectFaq.rejected ,( State, Action) => {
            State.loading = false;
            State.faq = {};
            State.error = Action.error?.message;
        })

        // Create Faq
        Builder.addCase( CreateFaq.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateFaq.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faq = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateFaq.rejected ,( State, Action) => {
            State.loading = false;
            State.faq = {};
            State.error = Action.error?.message;
        })

        // Update Faq
        Builder.addCase( UpdateFaq.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateFaq.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faq = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateFaq.rejected ,( State, Action) => {
            State.loading = false;
            State.faq = {};
            State.error = Action.error?.message;
        })

        // Delete Faq
        Builder.addCase( DeleteFaq.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteFaq.fulfilled, ( State, Action) => {
            State.loading = false;
            State.faq = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteFaq.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectFaqs = ( State: RootState ) => State.Faq;
export default FaqSlice.reducer;