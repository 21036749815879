import React, { FC } from 'react';

// Routes
import { RoutesList } from "./RoutesList";

// Interfaces
interface NavProviderInf {
    children?: React.ReactNode;
}

export const RoutesProvider: FC<NavProviderInf> = ({ children }) => {
    return (
        <React.Fragment>
            { children }
            <RoutesList />
        </React.Fragment>
    )
}