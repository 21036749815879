// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Faqs
export const GetAllFaqsAction = async () => {
    const response = await Request.get( '/faqs' );
    return response.data;
}

// Get All Faqs For Courses
export const GetAllFaqsCoursesAction = async () => {
    const response = await Request.get( '/faqs/courses' );
    return response.data;
}

// Get All Faqs For Mentors
export const GetAllFaqsMentorsAction = async () => {
    const response = await Request.get( '/faqs/mentors' );
    return response.data;
}

// Select Faq
export const SelectFaqAction = async ( Payload: number ) => {
    const response = await Request.get( `/faqs/${Payload}` );
    return response.data;
}

// Create Faq Interface
export interface CreateFaqInterface {
    question: string;
    answer: string;
    courses: boolean;
    mentors: boolean;
}

// Create Faq
export const CreateFaqAction = async ( Payload: CreateFaqInterface ) => {
    const response = await Request.post( '/faqs', Payload );
    return response.data;
}

// Update Faq Interface
export interface UpdateFaqInterface {
    id: number;
    question: string;
    answer: string;
    courses: boolean;
    mentors: boolean;
}

// Update Faq
export const UpdateFaqAction = async ( Payload: UpdateFaqInterface ) => {
    const response = await Request.post( `/faqs/${Payload.id}`, Payload );
    return response.data;
}

// Delete Faq
export const DeleteFaqAction = async ( Payload: number ) => {
    const response = await Request.delete( `/faqs/${Payload}` );
    return response.data;
}