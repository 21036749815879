// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllDownloadsAction, GetAllDownloadsGlobalAction,
         SelectDownloadGlobalAction, SelectDownloadAction,
         CreateDownloadInterface, CreateDownloadAction,
         UpdateDownloadInterface, UpdateDownloadAction,
         DeleteDownloadAction } from '../Actions/DownloadAction';

// Interface
interface IDownloads {
    loading?: boolean;
    downloads?: any;
    download?: any;
    error?: any;
}

// Initial State
const initialState: IDownloads = {
    loading: false,
    downloads: [],
    download: {},
    error: ''
}

// Get All Downloads
export const GetAllDownloads = createAsyncThunk(
    'Downloads',
    async () => {
        return await GetAllDownloadsAction();
    }
)

// Get All Downloads Globally
export const GetAllDownloadsGlobal = createAsyncThunk(
    'Downloads/Global',
    async () => {
        return await GetAllDownloadsGlobalAction();
    }
)

// Select Download
export const SelectDownload = createAsyncThunk(
    'Downloads/Select',
    async (Payload: number) => {
        return await SelectDownloadAction(Payload);
    }
)

// Select Download Globally
export const SelectDownloadGlobal = createAsyncThunk(
    'Downloads/Select/Global',
    async (Payload: number) => {
        return await SelectDownloadGlobalAction(Payload);
    }
)

// Create Download
export const CreateDownload = createAsyncThunk(
    'Downloads/Create',
    async (Payload: CreateDownloadInterface) => {
        return await CreateDownloadAction(Payload);
    }
)

// Update Download
export const UpdateDownload = createAsyncThunk(
    'Downloads/Update',
    async (Payload: UpdateDownloadInterface) => {
        return await UpdateDownloadAction(Payload);
    }
)

// Delete Download
export const DeleteDownload = createAsyncThunk(
    'Downloads/Delete',
    async (Payload: number) => {
        return await DeleteDownloadAction(Payload);
    }
)

const DownloadSlice = createSlice({
    name: 'Downloads',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Downloads
        Builder.addCase( GetAllDownloads.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllDownloads.fulfilled, ( State, Action) => {
            State.loading = false;
            State.downloads = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllDownloads.rejected ,( State, Action) => {
            State.loading = false;
            State.downloads = [];
            State.error = Action.error?.message;
        })

        // Get All Downloads Globally
        Builder.addCase( GetAllDownloadsGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllDownloadsGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.downloads = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllDownloadsGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.downloads = [];
            State.error = Action.error?.message;
        })

        // Select Download
        Builder.addCase( SelectDownload.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectDownload.fulfilled, ( State, Action) => {
            State.loading = false;
            State.download = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectDownload.rejected ,( State, Action) => {
            State.loading = false;
            State.download = {};
            State.error = Action.error?.message;
        })

        // Select Download Globally
        Builder.addCase( SelectDownloadGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectDownloadGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.download = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectDownloadGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.download = {};
            State.error = Action.error?.message;
        })

        // Create Download
        Builder.addCase( CreateDownload.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateDownload.fulfilled, ( State, Action) => {
            State.loading = false;
            State.download = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateDownload.rejected ,( State, Action) => {
            State.loading = false;
            State.download = {};
            State.error = Action.error?.message;
        })

        // Update Download
        Builder.addCase( UpdateDownload.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateDownload.fulfilled, ( State, Action) => {
            State.loading = false;
            State.download = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateDownload.rejected ,( State, Action) => {
            State.loading = false;
            State.download = {};
            State.error = Action.error?.message;
        })

        // Delete Download
        Builder.addCase( DeleteDownload.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteDownload.fulfilled, ( State, Action) => {
            State.loading = false;
            State.download = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteDownload.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectDownloads = ( State: RootState ) => State.Download;
export default DownloadSlice.reducer;