// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllCategoriesAction, GetAllCategoriesGlobalAction,
         SelectCategoryGlobalAction, SelectCategoryAction,
         SelectCategoryCoursesAction, CreateCategoryInterface,
         CreateCategoryAction, UpdateCategoryInterface,
         UpdateCategoryAction, DeleteCategoryAction } from '../Actions/CategoryAction';

// Interface
interface ICategories {
    loading?: boolean;
    categories?: any;
    category?: any;
    courses?: any;
    error?: any;
}

// Initial State
const initialState: ICategories = {
    loading: false,
    categories: [],
    category: {},
    courses: [],
    error: ''
}

// Get All Categories
export const GetAllCategories = createAsyncThunk(
    'Categories',
    async () => {
        return await GetAllCategoriesAction();
    }
)

// Get All Categories Globally
export const GetAllCategoriesGlobal = createAsyncThunk(
    'Categories/Global',
    async () => {
        return await GetAllCategoriesGlobalAction();
    }
)

// Select Category
export const SelectCategory = createAsyncThunk(
    'Categories/Select',
    async (Payload: number) => {
        return await SelectCategoryAction(Payload);
    }
)

// Select Category Globally
export const SelectCategoryGlobal = createAsyncThunk(
    'Categories/Select/Global',
    async (Payload: number) => {
        return await SelectCategoryGlobalAction(Payload);
    }
)

// Select Category Courses
export const SelectCategoryCourses = createAsyncThunk(
    'Categories/Select/Courses',
    async (Payload: number) => {
        return await SelectCategoryCoursesAction(Payload);
    }
)

// Create Category
export const CreateCategory = createAsyncThunk(
    'Categories/Create',
    async (Payload: CreateCategoryInterface) => {
        return await CreateCategoryAction(Payload);
    }
)

// Update Category
export const UpdateCategory = createAsyncThunk(
    'Categories/Update',
    async (Payload: UpdateCategoryInterface) => {
        return await UpdateCategoryAction(Payload);
    }
)

// Delete Category
export const DeleteCategory = createAsyncThunk(
    'Categories/Delete',
    async (Payload: number) => {
        return await DeleteCategoryAction(Payload);
    }
)

const CategorySlice = createSlice({
    name: 'Categories',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Categories
        Builder.addCase( GetAllCategories.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllCategories.fulfilled, ( State, Action) => {
            State.loading = false;
            State.categories = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllCategories.rejected ,( State, Action) => {
            State.loading = false;
            State.categories = [];
            State.error = Action.error?.message;
        })

        // Get All Categories Globally
        Builder.addCase( GetAllCategoriesGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllCategoriesGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.categories = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllCategoriesGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.categories = [];
            State.error = Action.error?.message;
        })

        // Select Category
        Builder.addCase( SelectCategory.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCategory.fulfilled, ( State, Action) => {
            State.loading = false;
            State.category = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectCategory.rejected ,( State, Action) => {
            State.loading = false;
            State.category = {};
            State.error = Action.error?.message;
        })

        // Select Category Globally
        Builder.addCase( SelectCategoryGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCategoryGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.category = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectCategoryGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.category = {};
            State.error = Action.error?.message;
        })

        // Select Category Courses
        Builder.addCase( SelectCategoryCourses.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCategoryCourses.fulfilled, ( State, Action) => {
            State.loading = false;
            State.courses = Action.payload?.data?.courses;
            State.error = '';
        })
        Builder.addCase( SelectCategoryCourses.rejected ,( State, Action) => {
            State.loading = false;
            State.courses = [];
            State.error = Action.error?.message;
        })

        // Create Category
        Builder.addCase( CreateCategory.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateCategory.fulfilled, ( State, Action) => {
            State.loading = false;
            State.category = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateCategory.rejected ,( State, Action) => {
            State.loading = false;
            State.category = {};
            State.error = Action.error?.message;
        })

        // Update Category
        Builder.addCase( UpdateCategory.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateCategory.fulfilled, ( State, Action) => {
            State.loading = false;
            State.category = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateCategory.rejected ,( State, Action) => {
            State.loading = false;
            State.category = {};
            State.error = Action.error?.message;
        })

        // Delete Category
        Builder.addCase( DeleteCategory.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteCategory.fulfilled, ( State, Action) => {
            State.loading = false;
            State.category = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteCategory.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectCategories = ( State: RootState ) => State.Category;
export default CategorySlice.reducer;