// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllReviewsAction, SelectReviewAction,
         CreateReviewInterface, CreateReviewAction,
         CreateReviewClientInterface, CreateReviewClientAction,
         UpdateReviewInterface, UpdateReviewAction,
         DeleteReviewAction } from '../Actions/ReviewAction';

// Interface
interface IReviews {
    loading?: boolean;
    reviews?: any;
    review?: any;
    error?: any;
}

// Initial State
const initialState: IReviews = {
    loading: false,
    reviews: [],
    review: {},
    error: ''
}

// Get All Reviews
export const GetAllReviews = createAsyncThunk(
    'Reviews',
    async () => {
        return await GetAllReviewsAction();
    }
)

// Select Review
export const SelectReview = createAsyncThunk(
    'Reviews/Select',
    async (Payload: number) => {
        return await SelectReviewAction(Payload);
    }
)

// Create Review
export const CreateReview = createAsyncThunk(
    'Reviews/Create',
    async (Payload: CreateReviewInterface) => {
        return await CreateReviewAction(Payload);
    }
)

// Create Review By Client
export const CreateReviewClient = createAsyncThunk(
    'Reviews/Create/Client',
    async (Payload: CreateReviewClientInterface) => {
        return await CreateReviewClientAction(Payload);
    }
)

// Update Review
export const UpdateReview = createAsyncThunk(
    'Reviews/Update',
    async (Payload: UpdateReviewInterface) => {
        return await UpdateReviewAction(Payload);
    }
)

// Delete Review
export const DeleteReview = createAsyncThunk(
    'Reviews/Delete',
    async (Payload: number) => {
        return await DeleteReviewAction(Payload);
    }
)

const ReviewSlice = createSlice({
    name: 'Reviews',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Reviews
        Builder.addCase( GetAllReviews.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllReviews.fulfilled, ( State, Action) => {
            State.loading = false;
            State.reviews = Action.payload?.data?.data;
            State.error = '';
        })
        Builder.addCase( GetAllReviews.rejected ,( State, Action) => {
            State.loading = false;
            State.reviews = [];
            State.error = Action.error?.message;
        })

        // Select Review
        Builder.addCase( SelectReview.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectReview.fulfilled, ( State, Action) => {
            State.loading = false;
            State.review = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectReview.rejected ,( State, Action) => {
            State.loading = false;
            State.review = {};
            State.error = Action.error?.message;
        })

        // Create Review
        Builder.addCase( CreateReview.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateReview.fulfilled, ( State, Action) => {
            State.loading = false;
            State.review = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateReview.rejected ,( State, Action) => {
            State.loading = false;
            State.review = {};
            State.error = Action.error?.message;
        })

        // Create Review By Client
        Builder.addCase( CreateReviewClient.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateReviewClient.fulfilled, ( State, Action) => {
            State.loading = false;
            State.review = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateReviewClient.rejected ,( State, Action) => {
            State.loading = false;
            State.review = {};
            State.error = Action.error?.message;
        })

        // Update Review
        Builder.addCase( UpdateReview.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateReview.fulfilled, ( State, Action) => {
            State.loading = false;
            State.review = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateReview.rejected ,( State, Action) => {
            State.loading = false;
            State.review = {};
            State.error = Action.error?.message;
        })

        // Delete Review
        Builder.addCase( DeleteReview.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteReview.fulfilled, ( State, Action) => {
            State.loading = false;
            State.review = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteReview.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectReviews = ( State: RootState ) => State.Review;
export default ReviewSlice.reducer;