// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Accreditations
export const GetAllAccreditationsAction = async () => {
    const response = await Request.get( '/accreditations' );
    return response.data;
}

// Get All Accreditations Globally
export const GetAllAccreditationsGlobalAction = async () => {
    const response = await Request.get( '/accreditations/global' );
    return response.data;
}

// Select Accreditation
export const SelectAccreditationAction = async ( Payload: number ) => {
    const response = await Request.get( `/accreditations/${Payload}` );
    return response.data;
}

// Select Accreditation Globally
export const SelectAccreditationGlobalAction = async ( Payload: number ) => {
    const response = await Request.get( `/accreditations/${Payload}/global` );
    return response.data;
}

// Select Accreditation Courses
export const SelectAccreditationCoursesAction = async ( Payload: number ) => {
    const response = await Request.get( `/accreditations/${Payload}/courses` );
    return response.data;
}

// Create Accreditation Interface
export interface CreateAccreditationInterface {
    name: string;
    description: string;
    parent_id?: string[];
    image: any;
}

// Create Accreditation
export const CreateAccreditationAction = async ( Payload: CreateAccreditationInterface ) => {
    const response = await Request.post( '/accreditations', Payload );
    return response.data;
}

// Update Accreditation Interface
export interface UpdateAccreditationInterface {
    id: number;
    name: string;
    description: string;
    parent_id?: string[];
    image: any;
}

// Update Accreditation
export const UpdateAccreditationAction = async ( Payload: UpdateAccreditationInterface ) => {
    const response = await Request.post( `/accreditations/${Payload.id}`, Payload );
    return response.data;
}

// Delete Accreditation
export const DeleteAccreditationAction = async ( Payload: number ) => {
    const response = await Request.delete( `/accreditations/${Payload}` );
    return response.data;
}