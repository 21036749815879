// Redux Toolkit
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// Application Reducers
import UserSlice from "./Reducers/UserSlice";
import ClientSlice from "./Reducers/ClientSlice";
import CourseSlice from "./Reducers/CourseSlice";
import CategorySlice from "./Reducers/CategorySlice";
import AccreditationSlice from "./Reducers/AccreditationSlice";
import MentorSlice from "./Reducers/MentorSlice";
import DownloadSlice from "./Reducers/DownloadSlice";
import FaqSlice from "./Reducers/FaqSlice";
import ReviewSlice from "./Reducers/ReviewSlice";
import CountrySlice from "./Reducers/CountrySlice";
import StateSlice from "./Reducers/StateSlice";
import CartSlice from "./Reducers/CartSlice";
import LanguageSlice from "./Reducers/LanguageSlice";
import LocationSlice from "./Reducers/LocationSlice";
import CertificateSlice from "./Reducers/CertificateSlice";

export const MasterStore = configureStore({
    reducer: {
        User: UserSlice,
        Client: ClientSlice,
        Course: CourseSlice,
        Category: CategorySlice,
        Accreditation: AccreditationSlice,
        Mentor: MentorSlice,
        Download: DownloadSlice,
        Faq: FaqSlice,
        Review: ReviewSlice,
        Country: CountrySlice,
        State: StateSlice,
        Cart: CartSlice,
        Language: LanguageSlice,
        Location: LocationSlice,
        Certificate: CertificateSlice
    },
});

// Export RootState and AppDispatch
export type AppDispatch = typeof MasterStore.dispatch;
export type RootState = ReturnType<typeof MasterStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction< ReturnType, RootState, unknown, Action<string>>;