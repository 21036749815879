// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Locations
export const GetAllLocationsAction = async () => {
    const response = await Request.get( '/locations' );
    return response.data;
}

// Get All Locations Globally
export const GetAllLocationsGlobalAction = async () => {
    const response = await Request.get( '/locations/global' );
    return response.data;
}

// Select Location
export const SelectLocationAction = async ( Payload: number ) => {
    const response = await Request.get( `/locations/${Payload}` );
    return response.data;
}

// Select Location Globally
export const SelectLocationGlobalAction = async ( Payload: number ) => {
    const response = await Request.get( `/locations/${Payload}/global` );
    return response.data;
}

// Create Location Interface
export interface CreateLocationInterface {
    live: boolean;
    country_id: number;
    state_id: number;
}

// Create Location
export const CreateLocationAction = async ( Payload: CreateLocationInterface ) => {
    const response = await Request.post( '/locations', Payload );
    return response.data;
}

// Update Location Interface
export interface UpdateLocationInterface {
    id: number;
    live: boolean;
    country_id: number;
    state_id: number;
}

// Update Location
export const UpdateLocationAction = async ( Payload: UpdateLocationInterface ) => {
    const response = await Request.post( `/locations/${Payload.id}`, Payload );
    return response.data;
}

// Delete Location
export const DeleteLocationAction = async ( Payload: number ) => {
    const response = await Request.delete( `/locations/${Payload}` );
    return response.data;
}