// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Courses
export const GetAllCoursesAction = async () => {
    const response = await Request.get( '/courses' );
    return response.data;
}

// Get All Courses Globally
export const GetAllCoursesGlobalAction = async () => {
    const response = await Request.get( '/courses/global' );
    return response.data;
}

// Select Course
export const SelectCourseAction = async ( Payload: number ) => {
    const response = await Request.get( `/courses/${Payload}` );
    return response.data;
}

// Select Course Globally
export const SelectCourseGlobalAction = async ( Payload: number ) => {
    const response = await Request.get( `/courses/${Payload}/global` );
    return response.data;
}

// Create Course Interface
export interface CreateCourseInterface {
    name: string;
    description: string;
    overview: string;
    image: any;
    brochure: any;
    certificate: any;
    language_id: string;
    level_id: string;
    categories: string[];
    accreditations: string[];
    mentors: string[];
    briefs: string[];
    attendees: string[];
    outlines: {
        name: string;
        details: {
            name: string;
            description: string;
        }[];
    }[];
    dates: {
        from: string;
        to: string;
        location_id: string;
    }[];
}

// Create Course
export const CreateCourseAction = async ( Payload: CreateCourseInterface ) => {
    const response = await Request.post( '/courses', Payload );
    return response.data;
}

// Update Course Interface
export interface UpdateCourseInterface {
    id: number;
    name: string;
    description: string;
    overview: string;
    image: any;
    brochure: any;
    certificate: any;
    language_id: string;
    level_id: string;
    categories: string[];
    accreditations: string[];
    mentors: string[];
    briefs: string[];
    attendees: string[];
    outlines: {
        id: number;
        name: string;
        details: {
            id: number;
            name: string;
            description: string;
        }[];
    }[];
    dates: {
        id: number;
        from: string;
        to: string;
        location_id: string;
    }[];
}

// Update Course
export const UpdateCourseAction = async ( Payload: UpdateCourseInterface ) => {
    const response = await Request.post( `/courses/${Payload.id}`, Payload );
    return response.data;
}

// Delete Course
export const DeleteCourseAction = async ( Payload: number ) => {
    const response = await Request.delete( `/courses/${Payload}` );
    return response.data;
}