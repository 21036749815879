// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllCartsAction, SelectClientCartsAction,
         SelectCartAction, UpdateCartInterface,
         UpdateCartAction, DeleteCartAction,
         DeleteClientCartsAction, CreateCartInterface,
         CreateCartAction } from '../Actions/CartAction';

// Interface
interface ICart {
    loading?: boolean;
    carts?: any;
    localCarts?: any;
    error?: any;
}

// Initial State
const initialState: ICart = {
    loading: false,
    carts: [],
    localCarts: {},
    error: ''
}

// Get All Carts
export const GetAllCarts = createAsyncThunk(
    'Carts',
    async () => {
        return await GetAllCartsAction();
    }
)

// Select Cart
export const SelectCart = createAsyncThunk(
    'Carts/Select',
    async (Payload: number) => {
        return await SelectCartAction(Payload);
    }
)

// Select Client Carts
export const SelectClientCarts = createAsyncThunk(
    'Carts/Client/Select',
    async () => {
        return await SelectClientCartsAction();
    }
)

// Create Cart
export const CreateCart = createAsyncThunk(
    'Carts/Create',
    async (Payload: CreateCartInterface) => {
        return await CreateCartAction(Payload);
    }
)

// Update Cart
export const UpdateCart = createAsyncThunk(
    'Carts/Update',
    async (Payload: UpdateCartInterface) => {
        return await UpdateCartAction(Payload);
    }
)

// Delete Cart
export const DeleteCart = createAsyncThunk(
    'Carts/Delete',
    async (Payload: number) => {
        return await DeleteCartAction(Payload);
    }
)

// Delete Client Carts
export const DeleteClientCarts = createAsyncThunk(
    'Carts/Client/Delete',
    async () => {
        return await DeleteClientCartsAction();
    }
)

const CartSlice = createSlice({
    name: 'Cart',
    initialState,
    reducers: {
        LocalCarts: (State, Action) => {
            State.loading = false;
            State.carts = [];
            State.localCarts = {...State.localCarts, ...Action.payload};
            State.error = '';
        }
    },
    extraReducers: ( Builder) => {

        // Get All Carts
        Builder.addCase( GetAllCarts.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllCarts.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = Action.payload?.data?.data;
            State.localCarts = {};
            State.error = '';
        })
        Builder.addCase( GetAllCarts.rejected ,( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.error = Action.error?.message;
        })

        // Select Cart
        Builder.addCase( SelectCart.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectCart.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = Action.payload?.data;
            State.localCarts = {};
            State.error = '';
        })
        Builder.addCase( SelectCart.rejected ,( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.error = Action.error?.message;
        })

        // Select Client Carts
        Builder.addCase( SelectClientCarts.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectClientCarts.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = Action.payload?.data;
            State.localCarts = {};
            State.error = '';
        })
        Builder.addCase( SelectClientCarts.rejected ,( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.error = Action.error?.message;
        })

        // Create Cart
        Builder.addCase( CreateCart.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateCart.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = Action.payload?.data;
            State.localCarts = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateCart.rejected ,( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.error = Action.error?.message;
        })

        // Update Cart
        Builder.addCase( UpdateCart.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateCart.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = Action.payload?.data;
            State.localCarts = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateCart.rejected ,( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.error = Action.error?.message;
        })

        // Delete Cart
        Builder.addCase( DeleteCart.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteCart.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.localCarts = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteCart.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

        // Delete Client Carts
        Builder.addCase( DeleteClientCarts.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteClientCarts.fulfilled, ( State, Action) => {
            State.loading = false;
            State.carts = [];
            State.localCarts = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteClientCarts.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    }
});

export const { LocalCarts } = CartSlice.actions;
export const SelectCarts = ( State: RootState ) => State.Cart;
export default CartSlice.reducer;