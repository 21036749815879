// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Carts
export const GetAllCartsAction = async () => {
    const response = await Request.get( '/carts' );
    return response.data;
}

// Select Cart
export const SelectCartAction = async ( Payload: number ) => {
    const response = await Request.get( `/carts/${Payload}` );
    return response.data;
}

// Select Client Carts
export const SelectClientCartsAction = async () => {
    const response = await Request.get( '/carts/client' );
    return response.data;
}

// Create Cart Interface
export interface CreateCartInterface {
    cart: {
        type?: string;
        course_id?: string;
        course_dates_id?: string;
        mentor_id?: string;
        mentor_sessions_id?: string;
        mentor_dates?: {
            mentor_dates_id: string;
            mentor_times_id: string;
        }[];
    }[];
    Navigate: any;
}

// Create Cart
export const CreateCartAction = async ( Payload: CreateCartInterface ) => {
    const response = await Request.post( '/carts', Payload );
    Payload.Navigate('/shopping-cart', { replace: true });
    return response.data;
}

// Update Cart Interface
export interface UpdateCartInterface {
    id: number;
    quantity?: number;
}

// Update Cart
export const UpdateCartAction = async ( Payload: UpdateCartInterface ) => {
    const response = await Request.post( `/carts/${Payload.id}`, {...Payload, _method: 'PUT'} );
    return response.data;
}

// Delete Cart
export const DeleteCartAction = async ( Payload: number ) => {
    const response = await Request.delete( `/carts/${Payload}` );
    return response.data;
}

// Delete Carts
export const DeleteClientCartsAction = async () => {
    const response = await Request.delete( '/carts/client' );
    return response.data;
}