import { createTheme } from '@mui/material/styles';

const CustomTheme = createTheme({
    palette: {
        primary: {
            main: '#40a5d6',
            dark: '#191919',
            light: '#eff3f5',
            contrastText: '#2c2c2c',
        },
        secondary: {
            main: '#c4d1d6',
            light: '#dce7eb'
        }
    },
});

export default CustomTheme;