import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

// React Redux
import { Provider } from "react-redux";

// Master Store
import { MasterStore } from './Services/MasterStore/MasterStore';

// React Router
import { BrowserRouter } from "react-router-dom";

// Main Application
import { Application } from './Application';

// Report
import reportWebVitals from './reportWebVitals';

// Theme Provider
import { ThemeProvider } from '@mui/material/styles';

// React Toastify
import { ToastContainer } from 'react-toastify';

// Reviews Styles
import '@splidejs/react-splide/css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

// Main Style
import CustomTheme from './Master.Style';

const Index = ReactDOM.createRoot(
  document.getElementById('MasterApp') as HTMLElement
);

Index.render(
    <React.Fragment>
        <ThemeProvider theme={CustomTheme}>
            <Provider store={ MasterStore }>
                <Suspense fallback={<h3>Loading ...</h3>}>
                    <BrowserRouter>
                        <Application />
                        <ToastContainer />
                    </BrowserRouter>
                </Suspense>
            </Provider>
        </ThemeProvider>
    </React.Fragment>
);

reportWebVitals();
