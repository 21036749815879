// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// React Toastify
import { toast } from 'react-toastify';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { GetAllLocationsAction, GetAllLocationsGlobalAction,
         SelectLocationGlobalAction, SelectLocationAction,
         CreateLocationInterface, CreateLocationAction,
         UpdateLocationInterface, UpdateLocationAction,
         DeleteLocationAction } from '../Actions/LocationAction';

// Interface
interface ILocations {
    loading?: boolean;
    locations?: any;
    location?: any;
    courses?: any;
    error?: any;
}

// Initial State
const initialState: ILocations = {
    loading: false,
    locations: [],
    location: {},
    courses: [],
    error: ''
}

// Get All Locations
export const GetAllLocations = createAsyncThunk(
    'Locations',
    async () => {
        return await GetAllLocationsAction();
    }
)

// Get All Locations Globally
export const GetAllLocationsGlobal = createAsyncThunk(
    'Locations/Global',
    async () => {
        return await GetAllLocationsGlobalAction();
    }
)

// Select Location
export const SelectLocation = createAsyncThunk(
    'Locations/Select',
    async (Payload: number) => {
        return await SelectLocationAction(Payload);
    }
)

// Select Location Globally
export const SelectLocationGlobal = createAsyncThunk(
    'Locations/Select/Global',
    async (Payload: number) => {
        return await SelectLocationGlobalAction(Payload);
    }
)

// Create Location
export const CreateLocation = createAsyncThunk(
    'Locations/Create',
    async (Payload: CreateLocationInterface) => {
        return await CreateLocationAction(Payload);
    }
)

// Update Location
export const UpdateLocation = createAsyncThunk(
    'Locations/Update',
    async (Payload: UpdateLocationInterface) => {
        return await UpdateLocationAction(Payload);
    }
)

// Delete Location
export const DeleteLocation = createAsyncThunk(
    'Locations/Delete',
    async (Payload: number) => {
        return await DeleteLocationAction(Payload);
    }
)

const LocationSlice = createSlice({
    name: 'Locations',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Get All Locations
        Builder.addCase( GetAllLocations.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllLocations.fulfilled, ( State, Action) => {
            State.loading = false;
            State.locations = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( GetAllLocations.rejected ,( State, Action) => {
            State.loading = false;
            State.locations = [];
            State.error = Action.error?.message;
        })

        // Get All Locations Globally
        Builder.addCase( GetAllLocationsGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( GetAllLocationsGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.locations = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( GetAllLocationsGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.locations = [];
            State.error = Action.error?.message;
        })

        // Select Location
        Builder.addCase( SelectLocation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectLocation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.location = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectLocation.rejected ,( State, Action) => {
            State.loading = false;
            State.location = {};
            State.error = Action.error?.message;
        })

        // Select Location Globally
        Builder.addCase( SelectLocationGlobal.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( SelectLocationGlobal.fulfilled, ( State, Action) => {
            State.loading = false;
            State.location = Action.payload?.data;
            State.error = '';
        })
        Builder.addCase( SelectLocationGlobal.rejected ,( State, Action) => {
            State.loading = false;
            State.location = {};
            State.error = Action.error?.message;
        })

        // Create Location
        Builder.addCase( CreateLocation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CreateLocation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.location = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( CreateLocation.rejected ,( State, Action) => {
            State.loading = false;
            State.location = {};
            State.error = Action.error?.message;
        })

        // Update Location
        Builder.addCase( UpdateLocation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( UpdateLocation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.location = Action.payload?.data;
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( UpdateLocation.rejected ,( State, Action) => {
            State.loading = false;
            State.location = {};
            State.error = Action.error?.message;
        })

        // Delete Location
        Builder.addCase( DeleteLocation.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( DeleteLocation.fulfilled, ( State, Action) => {
            State.loading = false;
            State.location = {};
            State.error = '';
            toast.success(Action.payload?.message, { position: "top-right", toastId: 'uniqueId' });
        })
        Builder.addCase( DeleteLocation.rejected ,( State, Action) => {
            State.loading = false;
            State.error = Action.error?.message;
        })

    },
});

export const SelectLocations = ( State: RootState ) => State.Location;
export default LocationSlice.reducer;