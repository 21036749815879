import React, { FC } from 'react';

// Axios Provider
import RequestProvider from './Middleware/Requests/RequestProvider';

// Routes
import { RoutesProvider } from "./Routes/RoutesProvider";

// Material UI
import { CssBaseline } from '@mui/material';

export const Application: FC = () => {
    return (
        <React.Fragment>
            <RequestProvider>
                <RoutesProvider>
                    <CssBaseline />
                </RoutesProvider>
            </RequestProvider>
        </React.Fragment>
    );
}
