import { FC, lazy } from "react";

// React Router
import { useRoutes, Navigate } from "react-router-dom";

// Containers
const Container = lazy( () => import('../Containers/Container') );

// Layouts
const SignIn = lazy(() => import('../Layouts/Authentication/SignIn/SignIn'));
const SignUp = lazy(() => import('../Layouts/Authentication/SignUp/SignUp'));
const ResetPassword = lazy(() => import('../Layouts/Authentication/ResetPass/ResetPass'));
const ForgotPassword = lazy(() => import('../Layouts/Authentication/ForgotPass/ForgotPass'));
const EmailVerified = lazy(() => import('../Layouts/Authentication/EmailVerified/EmailVerified'));

const Carts = lazy(() => import('../Layouts/Carts/Carts'));
const AboutUs = lazy(() => import('../Layouts/AboutUs/AboutUs'));
const InHouse = lazy(() => import('../Layouts/InHouse/InHouse'));
const HomePage = lazy(() => import('../Layouts/HomePage/HomePage'));
const Coaching = lazy(() => import('../Layouts/Coaching/Coaching'));
const ELearning = lazy(() => import('../Layouts/ELearning/ELearning'));
const Downloads = lazy(() => import('../Layouts/Downloads/Downloads'));
const ContactUs = lazy(() => import('../Layouts/ContactUs/ContactUs'));
const Courses = lazy(() => import('../Layouts/Courses/Courses/Courses'));
const Mentors = lazy(() => import('../Layouts/Mentors/Mentors/Mentors'));
const Consultancy = lazy(() => import('../Layouts/Consultancy/Consultancy'));
const Certificates = lazy(() => import('../Layouts/Certificates/Certificates'));
const SingleCourse = lazy(() => import('../Layouts/Courses/SingleCourse/SingleCourse'));
const SingleMentor = lazy(() => import('../Layouts/Mentors/SingleMentor/SingleMentor'));

export const RoutesList: FC = () => {
    const Token = localStorage.getItem('token');
    const IsAuthenticated = Token && JSON.parse(Token).access_token;

    return useRoutes([
        {
            path: "/",
            element: <Container />,
            children: [
                { path: "", element: <HomePage /> },
                { path: "courses", element: <Courses /> },
                { path: "mentors", element: <Mentors /> },
                { path: "about-us", element: <AboutUs /> },
                { path: "in-house", element: <InHouse /> },
                { path: "coaching", element: <Coaching /> },
                { path: "downloads", element: <Downloads /> },
                { path: "e-learning", element: <ELearning /> },
                { path: "contact-us", element: <ContactUs /> },
                { path: "consultancy", element: <Consultancy /> },
                { path: "courses/:id", element: <SingleCourse /> },
                { path: "mentors/:id", element: <SingleMentor /> },
                { path: "certificates", element: <Certificates /> },
                { path: "shopping-cart", element: IsAuthenticated ? <Carts /> : <Navigate to={'/sign-in'} replace /> },
                { path: "email-verified", element: IsAuthenticated ? <EmailVerified /> : <Navigate to={'/sign-in'} replace /> },

                { path: "sign-up", element: IsAuthenticated ? <Navigate to={'/'} replace />: <SignUp /> },
                { path: "sign-in", element: IsAuthenticated ? <Navigate to={'/'} replace /> : <SignIn /> },
                { path: "reset-password", element: IsAuthenticated ? <Navigate to={'/'} replace /> : <ResetPassword /> },
                { path: "forgot-password", element: IsAuthenticated ? <Navigate to={'/'} replace /> : <ForgotPassword /> }
            ]
        },{ path: "/*", element: <h1>404</h1> }
    ]);
}