// Redux Toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Master Store
import { RootState } from "../MasterStore";

// Actions
import { CertificateSearchAction } from '../Actions/CertificateAction';

// Interface
interface ICertificates {
    loading?: boolean;
    certificates?: any;
    certificate?: any;
    error?: any;
}

// Initial State
const initialState: ICertificates = {
    loading: false,
    certificates: [],
    certificate: {},
    error: ''
}

// Certificate Search
export const CertificateSearch = createAsyncThunk(
    'Certificates/Search',
    async (Payload: string) => {
        return await CertificateSearchAction(Payload);
    }
)

const CertificateSlice = createSlice({
    name: 'Certificates',
    initialState,
    reducers: {},
    extraReducers: ( Builder) => {

        // Certificate Search
        Builder.addCase( CertificateSearch.pending, ( State) => {
            State.loading = true;
        })
        Builder.addCase( CertificateSearch.fulfilled, ( State, Action) => {
            State.loading = false;
            State.certificate = (Action.payload?.data && Action.payload.data.length) ? Action.payload.data[0] : {};
            State.error = '';
        })
        Builder.addCase( CertificateSearch.rejected ,( State, Action) => {
            State.loading = false;
            State.certificate = {};
            State.error = Action.error?.message;
        })

    },
});

export const SelectCertificates = ( State: RootState ) => State.Certificate;
export default CertificateSlice.reducer;