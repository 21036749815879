// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

// Get All Reviews
export const GetAllReviewsAction = async () => {
    const response = await Request.get( '/reviews' );
    return response.data;
}

// Select Review
export const SelectReviewAction = async ( Payload: number ) => {
    const response = await Request.get( `/reviews/${Payload}` );
    return response.data;
}

// Create Review Interface
export interface CreateReviewInterface {
    company: string;
    review: string;
    approved: boolean;
    client_id?: string;
    course_id?: string;
    mentor_id?: string;
}

// Create Review
export const CreateReviewAction = async ( Payload: CreateReviewInterface ) => {
    const response = await Request.post( '/reviews', Payload );
    return response.data;
}

// Create Review By Client Interface
export interface CreateReviewClientInterface {
    company: string;
    review: string;
    course_id?: string;
    mentor_id?: string;
}

// Create Review By Client
export const CreateReviewClientAction = async ( Payload: CreateReviewClientInterface ) => {
    const response = await Request.post( '/reviews/review', Payload );
    return response.data;
}

// Update Review Interface
export interface UpdateReviewInterface {
    id: number;
    company: string;
    review: string;
    approved: boolean;
    client_id?: string;
    course_id?: string;
    mentor_id?: string;
}

// Update Review
export const UpdateReviewAction = async ( Payload: UpdateReviewInterface ) => {
    const response = await Request.post( `/reviews/${Payload.id}`, Payload );
    return response.data;
}

// Delete Review
export const DeleteReviewAction = async ( Payload: number ) => {
    const response = await Request.delete( `/reviews/${Payload}` );
    return response.data;
}